import EmailCard from "./EmailCard";

const logo = require('../assets/img/logo_no_bg.png')

export default function Landing() {
  return (
    <div className="h-screen bg-gradient-to-b from-[#00F704] via-[#00F704] to-white">
      <div className="flex flex-col items-center justify-center pt-[20px]">
        <div className="flex items-center sm:max-w-full lg:max-w-lg max-h-min">
          <img src={logo}/>
        </div>
        <EmailCard/>
      </div>
    </div>
  )
}