import React, { useState } from 'react'
import { subscribeEmail } from "./api/api"

export default function EmailCard() {

  const [email, setEmail] = useState('')
  const [didSubscribe, setDidSubscribe] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    const isSuccess = await subscribeEmail(email)
    if (isSuccess) {
      setDidSubscribe(true)
    }
    setIsLoading(false)
  }

  const buttonDisabled = !email || email.length === 0 || isLoading
  const buttonClass = `${buttonDisabled ? 'bg-gray-900 cursor-not-allowed text-gray-400' : 'bg-black text-white'}`

  return (
    <div className="w-full">
      <div className="mx-auto max-w-md px-4 sm:max-w-full sm:px-6 lg:max-w-7xl lg:px-8">
        {!didSubscribe ?
          (
            <div className="relative w-full flex flex-col items-center">
              <div className="sm:text-center">
                <h2 className="text-3xl font-bold text-black tracking-tight sm:text-8xl lg:text-4xl sm:tracking-tight">Get notified when we&rsquo;re launching.</h2>
              </div>
              <form onSubmit={handleSubmit} className="mt-12 sm:flex">
                <div className="">
                  <label for="cta-email" className="sr-only">Email address</label>
                  <input 
                    id="cta-email" 
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                    type="email" 
                    autoComplete='off'
                    className="block w-full border-2 border-black bg-transparent placeholder-black px-5 py-3 text-base text-black focus:ring-pt-black focus:border-pt-black font-medium" 
                    placeholder="Enter your email"/>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button type="submit" disabled={!email || email.length === 0} className={`block w-full border border-transparent px-5 py-3 ${buttonClass} text-base font-medium shadow focus:outline-none sm:px-10`}>
                    {isLoading && (
                      <svg role="status" class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#fff"/>
                      </svg>
                    )}
                    Notify me
                  </button>
                </div>
              </form>
            </div>
          ) 
          : (
            <div className="relative w-full flex flex-col items-center">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg class="h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-green-800">Successfully subscribed</p>
                </div>
              </div>
            </div>
          )
        }
    </div>
  </div>
  )
}