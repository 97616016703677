import axios from "axios"

export async function subscribeEmail(email) {
  console.log('attempt to subscribe email: ', email)
  // const url = `${process.env.API_BASE_URL}/email`
  const url = 'https://trapezoidal-hip-museum.glitch.me/email'
  console.log('url: ', url)
  
  const data = {
    email: email
  }

  return axios.post(url, data)
}